.galeria-container {
  padding: 90px 50px;
  padding-bottom: 40px;
  margin: auto;
  max-width: 1250px;
}

.cards-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.galeria-container h2 {
  color: #343434;
  margin-bottom: 20px;
  text-align: center;
  font-size: 32px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
}

.card img {
  width: 100%;
  height: 400px;
  /* Defina a altura desejada para as imagens */
  object-fit: cover;
  /* Ajuste as imagens para cobrir o contêiner, mantendo a proporção */
}

.card-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.3;

}

.card-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  line-height: 0;
  background-color: #fff;
}


.card-title {
  font-size: 18px;
  margin: 15px 0;
  margin-top: 0;
  line-height: 1.3;
  font-weight: 600;
}

.card-description {
  font-size: 14px;
  margin: 10px 0;
  line-height: 1.3;
}

.card-link {
  display: block;
  color: #fff;
  border-bottom: 1px solid #fff;
  width: fit-content;

}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.slick-slide>div {
  padding: 10px;
}

.custom-arrow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.custom-next {
  right: -50px !important;
}

.custom-prev {
  left: -50px !important;
}

.custom-arrow i {
  color: #038C3E;
  font-size: 60px;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {

  .galeria-container {
    padding: 60px 10px;
    padding-bottom: 40px;
  }

  .slick-next {
    display: none !important;

  }

  .slick-next {
    display: none !important;
  }
}




.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  /* Permite a rolagem se o conteúdo for muito grande */
}

.modal-content img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  /* Limita a altura máxima da imagem */
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}