.header-wrapper.scrolled {
  position: fixed;
  background-color: #038C3E;
  box-shadow: none;
  border-bottom: none;
  transition: 0.3s;
}

header img{max-width: 260px;}

.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 101;
  width: 100%;
  padding: 10px 0;
  padding-top: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: 0.3s;
}

.header-img-container button{
  background-color: transparent !important;
}

header {
  min-height: calc(90px - 41px);
  /* header - padding */
  padding: 0 55px !important;
  display: flex;
  align-items: center;
  z-index: 100;
  margin: auto;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
  max-width: 1250px;
}

.nav-container,
nav ul {
  display: flex;
  gap: 25px;
  list-style: none;
}

nav ul li button {
  background: none;
  font: inherit;
  cursor: pointer;
  color: #fff !important;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-links i {
  color: #fff !important;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.header-wrapper.scrolled button,
.header-wrapper.scrolled i{
  color: #ffffff !important;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px !important; 
}



/* Estilos para telas menores (mobile) */
@media (max-width: 768px) {
header{
  padding: 0 20px !important;
  padding-left: 0 !important;
}

.header-wrapper{
  padding: 10px 0;
}
  .nav-container {
    display: none;
  }

  .menu-toggle {
    display: block;
  }

}


