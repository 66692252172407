
.App{
  font-family: sans-serif;
}

.whats-btn a {
    background-color: #8FBF26;
    border-radius: 99px;
    bottom: 2rem;
    color: #fff;
    font-size: 60px;
    opacity: 1;
    padding: 10px;
    position: fixed;
    right: 2rem;
    transition: .4s;
    z-index: 0;
    z-index: 50;
}

.whats-btn a:hover {
    background-color: #fff;
    color: #8FBF26;
}
h2{
  color: #fff !important;
}

h2.alt{
  color: #038C3E !important;
}