.hero-wrapper {
  background-image: url('../../assets/imgs/bg.jpg');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50%;
  position: relative;
}

.hero-container {
  padding: 340px 60px;
  padding-bottom: 250px;
  max-width: 1250px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
}

.hero-container > div {
  flex: 1;
}

.hero-content {
  max-width: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hero-container p {
  color: #f1f1f1;
  font-size: 22px;
  line-height: 1.4;
  max-width: 600px;
}

.hero-container h1 {
  max-width: 500px;
  font-size: 3em;
  line-height: 1.1;
  letter-spacing: -1px;
  font-weight: bolder;
  color: #fff;
}

.hero-container h2 {
  color: #fff;
  font-weight: 400;
  font-size: 34px;
  
  line-height: 1.1;
}

.hero-img {
  width: 100%;
  background-color: #a2a2a2;
  min-height: 400px;
  max-height: 400px;
  border-radius: 30px;
}

@media (max-width: 768px) {
  .hero-container {
    padding: 260px 20px;
    padding-bottom: 185px;

    flex-direction: column;
  }
  .hero-content p {
    font-size: 18px;
  }
  .hero-content h1{
    font-size: 32px;
  }
  .hero-content h2{
    font-size: 24px;
  }
}


