.contato-wrapper {
  padding: 90px 20px;
  display: flex;
  flex-direction: column;
}

.map-container{
  max-width: 1170px;
  margin: auto;
  width: 100%;
  margin-bottom: 40px;
}

.map-container iframe{
  border: none;
  border-radius: 8px;
}

.contato-container {
  max-width: 1170px;
  
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.contato-container > div {
  flex: 1;
}

.contato-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contato-content h2 {
  font-size: 34px;
  color: #212a34;
}

.contato-content p {
  font-size: 18px;
  line-height: 1.3;
}

.contato-img {
  background-image: url('');
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #212a34;
  min-height: 400px;
  max-height: 400px;
}

@media (max-width: 768px) {
  .contato-container {
    padding-top: 10px;
    flex-direction: column;
  }

  .contato-wrapper {
    padding: 60px 20px;
  }
}


.form-column h2{
  margin-bottom: 20px;
  color: #faa31a;
  font-size: 28px;
}
.form-column p{
  margin-bottom: 20px;
  line-height: 1.3;
}

.info-column{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-column div{
  background-color: #eaeaea;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 20px;
}

.info-column p{
  line-height: 1.3;
}

.info-column .main-btn{
  margin-top: 0;
}

.form-column form{
  margin-top: 20px;
}
.form-column form input, 
.form-column form select,
.form-column form textarea {
  font-family: sans-serif;
      width: 100%;
    color: #7b8285;
    border-radius: 3px;
    height: 42px;
    padding: 10px 20px;
    border: 2px solid #f2f3f6;
    background-color: transparent;
    text-align: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: box-shadow .35s ease, border-color .35s ease;
    transition: box-shadow .35s ease, border-color .35s ease;
    margin-bottom: 20px;
}

.form-column form textarea{
  height: 150px;
}

.contato-flex{
  display: flex;
  gap: 20px;
}

.contato-flex div{
  flex: 1;
}
.form-column .main-btn{
  margin-top: 0;
}