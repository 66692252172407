.main-btn {
  background-color: #ffffff;
  border-radius: 4px;
  color: #0D0D0D;
  cursor: pointer;
  font-size: 18px;
  padding: 12px 36px;
  transition: 0.3s;
  display: block;
  margin-top: 15px;
  width: fit-content;
  
}


.main-btn:hover {
  background-color: #F2F2F2d3;
}

.main-btn.alt {
  background-color: #038C3E;
  color: rgb(255, 255, 255);
}

.main-btn.alt:hover {
  background-color: #038C3Ed3;
}
